
import Vue from 'vue';

interface ConfirmDialogDataInterface {
	show: boolean;
	title: string;
	message: string;
	cancelCallback: null | Function;
	confirmCallback: null | Function;
	confirmCallbackProps: null | object;
}

/*
	eslint-disable
	implicit-arrow-linebreak,
*/

export default Vue.extend({
	name: 'confirm-dialog',
	data: () =>
		({
			show: false,
			title: '',
			message: '',
			cancelCallback: null,
			confirmCallback: null,
			confirmCallbackProps: null,
		} as ConfirmDialogDataInterface),
	methods: {
		showDialog(
			title,
			message,
			cancelCallback,
			confirmCallback,
			confirmCallbackProps
		) {
			this.title = title;
			this.message = message;
			this.cancelCallback = cancelCallback;
			this.confirmCallback = confirmCallback;
			this.confirmCallbackProps = confirmCallbackProps;
			this.show = true;
		},
		cancel() {
			this.title = '';
			this.message = '';

			if (this.cancelCallback) {
				this.cancelCallback();
			}

			this.cancelCallback = null;
			this.confirmCallback = null;
			this.show = false;
		},
		confirm() {
			this.title = '';
			this.message = '';

			if (this.confirmCallback) {
				this.confirmCallback(this.confirmCallbackProps);
			}

			this.cancelCallback = null;
			this.confirmCallback = null;
			this.show = false;
		},
	},
});
