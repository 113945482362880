
import Vue from 'vue';

export default Vue.extend({
	name: 'saving',
	props: {
		show: Boolean,
		message: String,
		color: String,
	},
	data: () => ({}),
	methods: {},
});
