
import {
	LoopInterface,
	LoopMidiUploadInterface,
	PendingUploadInterface,
} from '@/interfaces/pack-upload-tool';
import Vue from 'vue';

interface LoopMidiUploadDialogDataInterface {
	show: boolean;
	loop: LoopInterface;
	loading: boolean;
}

/*
	eslint-disable
	implicit-arrow-linebreak,
*/

export default Vue.extend({
	name: 'loop-midi-upload-dialog',
	data: () =>
		({
			show: false,
			loop: null as any,
			loading: false,
		} as LoopMidiUploadDialogDataInterface),
	computed: {
		title() {
			return this.loop ? `Upload Midi: ${this.loop.name}` : 'Upload Midi: ';
		},
	},
	methods: {
		showDialog(loop) {
			this.loop = loop;
			this.show = true;
		},
		cancel() {
			this.loop = null as any;
			this.show = false;
		},
		triggerUploadInput() {
			const ref: any = this.$refs.midiUploadInput;
			if (ref) {
				ref.click();
			}
		},
		processLocalUpload(e: Event) {
			this.loading = true;
			try {
				const fileReader = new FileReader();
				const { files } = e.target as HTMLInputElement;

				if (files) {
					const file = files[0];

					let dataUrl;

					fileReader.addEventListener(
						'load',
						() => {
							dataUrl = fileReader.result;
							const upload: LoopMidiUploadInterface = {
								loop: this.loop as LoopInterface,
								midiName: this.loop.name,
								midiFileName: `${this.loop.fileName.split('.mp3')[0]}.midi`,
								file,
								src: dataUrl,
							};

							this.$emit('upload', {
								upload,
								callback: this.handleCompletedUpload,
							});
						},
						false
					);

					if (file) {
						fileReader.readAsDataURL(file);
					}
				}
			} catch (error) {
				console.error('Failed to add loop midi upload!');
				this.loading = false;
			}
		},
		handleCompletedUpload() {
			this.loading = false;
			this.loop = null as any;
			this.show = false;
		},
	},
});
