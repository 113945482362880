
import Vue from 'vue';
import { values } from 'lodash';

import ConfirmDialog from '@/components/PackUploadTool/ConfirmDialog.vue';

import * as PUTInterfaces from '../../interfaces/pack-upload-tool';

export default Vue.extend({
	components: {
		ConfirmDialog,
	},
	name: 'pack-info',
	props: {
		pack: Object as () => PUTInterfaces.PackInterface,
		entities: Object,
		pendingUploads: Object,
	},
	data: () => ({
		values,
	}),
	methods: {
		deletePack() {
			this.$emit('delete-pack');
		},
		handleChange(prop, val) {
			this.$emit('update-pack', [{ prop, val }]);
		},
		getDateString(value: string) {
			let dateStr: any = null;
			if (value) {
				dateStr = new Date(value).toISOString().substr(0, 10);
			}
			return dateStr;
		},
		triggerUploadInput(inputRefStr: string) {
			const inputEl: HTMLInputElement = this.$refs[
				inputRefStr
			] as HTMLInputElement;
			inputEl.click();
		},
		processLocalUpload(e: Event, prop: string) {
			try {
				const fileReader = new FileReader();
				const { files } = e.target as HTMLInputElement;

				if (files) {
					const file = files[0];

					let dataUrl;

					fileReader.addEventListener(
						'load',
						() => {
							dataUrl = fileReader.result;
							const upload: PUTInterfaces.PendingUploadInterface = {
								prop,
								file,
								src: dataUrl,
							};

							this.$emit('add-upload', upload);
						},
						false
					);

					if (file) {
						fileReader.readAsDataURL(file);
					}
				}
			} catch (error) {
				console.error('Failed to add pending upload!');
			}
		},
		clearPendingUploads() {
			const {
				thumbnailUploadInput,
				coverUploadInput,
				previewUploadInput,
			} = this.$refs as any;
			if (thumbnailUploadInput && coverUploadInput && previewUploadInput) {
				thumbnailUploadInput.value = '';
				coverUploadInput.value = '';
				previewUploadInput.value = '';
				this.$emit('clear-pending-uploads');
			}
		},
		completePendingUploads() {
			this.$emit('complete-pending-uploads');
		},
		deleteUpload(prop) {
			const { confirmDialog } = this.$refs as any;
			if (confirmDialog) {
				confirmDialog.showDialog(
					'Action cannot be undone!',
					`Are you sure you want to delete ${prop.split('Url')[0]}?`,
					null,
					this.emitDeleteUpload,
					prop
				);
			}
		},
		emitDeleteUpload(prop) {
			this.$emit('delete-upload', { prop, val: null });
		},
	},
});
