
import Vue from 'vue';
import { keyBy, snakeCase, values } from 'lodash';

import ConfirmDialog from '@/components/PackUploadTool/ConfirmDialog.vue';

import { VariantSchema } from '@/schemas/Variant.schema';
import * as PUTInterfaces from '../../interfaces/pack-upload-tool';

/*
	eslint-disable
	operator-linebreak
*/

export default Vue.extend({
	components: {
		ConfirmDialog,
	},
	name: 'variant-groups',
	props: {
		variantGroups: {
			type: Object as () => {
				[id: number]: PUTInterfaces.VariantGroupInterface;
			},
			required: true,
		},
		variantPendingUploads: {
			type: Object as () => {
				[id: number]: PUTInterfaces.PendingVariantUploadInterface;
			},
			required: true,
		},
		tempos: Object as () => {
			[id: number]: PUTInterfaces.TempoInterface;
		},
		styles: Object as () => {
			[id: number]: PUTInterfaces.StyleInterface;
		},
		productions: Object as () => {
			[id: number]: PUTInterfaces.ProductionInterface;
		},
		categories: Object as () => {
			[id: number]: PUTInterfaces.CategoryInterface;
		},
	},
	data: () => ({
		values,
	}),
	methods: {
		getTableHeaders() {
			const headers = Object.keys(VariantSchema).map(key => ({
				text: key,
				value: key,
			}));
			return headers.sort();
		},
		getTableData(variantGroup: PUTInterfaces.VariantGroupInterface) {
			return values(variantGroup.variants);
		},
		createVariantGroup() {
			this.$emit('create-variant-group');
		},
		deleteVariantGroup(variantGroup: PUTInterfaces.VariantGroupInterface) {
			const variantGroupName = `
				${variantGroup.tempo ? variantGroup.tempo.name : ''} -
				${variantGroup.style ? variantGroup.style.name : ''} -
				${variantGroup.production ? variantGroup.production.name : ''}
			`;

			const { confirmDialog } = this.$refs as any;
			if (confirmDialog) {
				confirmDialog.showDialog(
					'Action cannot be undone!',
					`Are you sure you want to delete ${variantGroupName} and all of its variants?`,
					null,
					this.emitDeleteVariantGroup,
					variantGroup
				);
			}
		},
		emitDeleteVariantGroup(variantGroup: PUTInterfaces.VariantGroupInterface) {
			this.$emit('delete-variant-group', variantGroup.id);
		},
		uploadVariants(variantGroup: PUTInterfaces.VariantGroupInterface) {
			this.$emit('create-variants', variantGroup.id);
		},
		selectVariantFiles(refName: string) {
			const ref: any = this.$refs[refName];
			if (ref && ref.length) {
				ref[0].click();
			}
		},
		processLocalVariantFiles(
			e: Event,
			variantGroup: PUTInterfaces.VariantGroupInterface
		) {
			this.$emit('process-local-variant-files', e, variantGroup);
		},
		isVariantFileSelectionDisabled(
			variantGroup: PUTInterfaces.VariantGroupInterface
		): boolean {
			return !(
				variantGroup.variantNameTemplate &&
				variantGroup.variantFileNameTemplate &&
				variantGroup.style &&
				variantGroup.tempo &&
				variantGroup.production
			);
		},
		isVariantUploadDisabled(
			variantGroup: PUTInterfaces.VariantGroupInterface
		): boolean {
			return !(
				variantGroup.variantNameTemplate &&
				variantGroup.variantFileNameTemplate &&
				variantGroup.style &&
				variantGroup.tempo &&
				variantGroup.production &&
				this.variantPendingUploads[variantGroup.id]
			);
		},
	},
});
