
import Vue from 'vue';
import { keyBy, snakeCase, values } from 'lodash';

import ConfirmDialog from '@/components/PackUploadTool/ConfirmDialog.vue';
import LoopMidiUploadDialog from '@/components/PackUploadTool/LoopMidiUploadDialog.vue';

import { LoopSchema } from '@/schemas/Loop.schema';
import * as PUTInterfaces from '../../interfaces/pack-upload-tool';

/*
	eslint-disable
	arrow-parens
*/

export default Vue.extend({
	components: {
		ConfirmDialog,
		LoopMidiUploadDialog,
	},
	name: 'pack-loops',
	props: {
		pack: Object as () => PUTInterfaces.PackInterface,
		loops: Object as () => { [id: number]: PUTInterfaces.LoopInterface },
		categories: Object as () => {
			[id: number]: PUTInterfaces.CategoryInterface;
		},
	},
	data: () => ({
		numLoopsToGenerate: 0,
		values,
	}),
	methods: {
		getTableHeaders() {
			const headers: any = [
				{ text: 'actions', value: 'actions', align: 'start' },
			];
			Object.keys(LoopSchema).forEach(key => {
				headers.push({
					text: key,
					value: key,
				});
			});
			return headers.sort();
		},
		getTableData() {
			return values(this.loops);
		},
		async generateLoops() {
			this.$emit('generate-loops', this.numLoopsToGenerate);
			this.numLoopsToGenerate = 0;
		},
		deleteLoops() {
			this.$emit('delete-loops');
		},
		handleDeleteLoops() {
			const { confirmDialog } = this.$refs as any;
			if (confirmDialog) {
				confirmDialog.showDialog(
					'Action cannot be undone!',
					'Are you sure you want to delete all loops?',
					null,
					this.deleteLoops,
					null
				);
			}
		},
		deleteLoop(loopId: string) {
			this.$emit('delete-loop', loopId);
		},
		handleDeleteLoop(loopId: string) {
			const { confirmDialog } = this.$refs as any;
			if (confirmDialog) {
				confirmDialog.showDialog(
					'Action cannot be undone!',
					`Are you sure you want to delete ${this.loops[loopId].name}?`,
					null,
					this.deleteLoop,
					loopId
				);
			}
		},
		handleChange(loopId, prop, val) {
			this.$emit('update-loop', [{ loopId, prop, val }]);
		},
		openMidiUploadDialog(loop) {
			const { loopMidiUploadDialog } = this.$refs as any;
			if (loopMidiUploadDialog) {
				loopMidiUploadDialog.showDialog(loop);
			}
		},
		handleLoopMidiUpload(payload) {
			this.$emit('upload-loop-midi', payload);
		},
	},
});
