
import Vue from 'vue';
import { keyBy, snakeCase, values } from 'lodash';

import * as PUTInterfaces from '../../interfaces/pack-upload-tool';

export default Vue.extend({
	name: 'pack-entity-table',
	props: {
		title: String,
		items: Object as () => { [id: number]: PUTInterfaces.PUTEntityInterfaces },
	},
	data: () => ({
		values,
	}),
	methods: {
		getTableHeaders() {
			const headers = [
				{
					text: 'name',
					value: 'name',
				},
				{
					text: 'id',
					value: '_id',
				},
			];
			return headers.sort();
		},
		getTableData() {
			return values(this.items);
		},
	},
});
