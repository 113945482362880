import { render, staticRenderFns } from "./PackUploadTool.vue?vue&type=template&id=b9de87b4&scoped=true"
import script from "./PackUploadTool.vue?vue&type=script&lang=ts"
export * from "./PackUploadTool.vue?vue&type=script&lang=ts"
import style0 from "./PackUploadTool.vue?vue&type=style&index=0&id=b9de87b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9de87b4",
  null
  
)

export default component.exports