
import Vue from 'vue';
import { NodeService } from '@/services/node.service';

import '../interfaces/Pack.interface';

import { snakeCase, values } from 'lodash';

import PackInfo from '@/components/PackUploadTool/PackInfo.vue';
import PackLoops from '@/components/PackUploadTool/PackLoops.vue';
import VariantGroups from '@/components/PackUploadTool/VariantGroups.vue';
import PackEntityTable from '@/components/PackUploadTool/PackEntityTable.vue';
import Saving from '@/components/PackUploadTool/Saving.vue';
import Error from '@/components/PackUploadTool/Error.vue';
import ConfirmDialog from '@/components/PackUploadTool/ConfirmDialog.vue';

import * as PUTInterfaces from '../interfaces/pack-upload-tool';

/*
	eslint-disable
	function-paren-newline,
	implicit-arrow-linebreak,
	arrow-parens,
	no-mixed-spaces-and-tabs,
	operator-linebreak,
	no-lonely-if,
	guard-for-in,
	arrow-body-style,
	no-bitwise
*/

const entityTypes = [
	'artist',
	'category',
	'genre',
	'pack-type',
	'production',
	'style',
	'tempo',
];

const CANNOT_SAVE_PACK_MESSAGE =
	'Cannot Save Pack - Missing Required Info Fields';

const DEFAULT_VARIANT_NAME_TEMPLATE =
	'{loopName}_{packAbbv}_{tempo}_{style}_{category}_{production}';
const DEFAULT_VARIANT_FILENAME_TEMPLATE =
	'{loopName}_{packAbbv}_{tempo}_{style}_{category}_{production}.mp3';

export default Vue.extend({
	props: {
		packId: String,
	},
	components: {
		PackInfo,
		PackLoops,
		VariantGroups,
		PackEntityTable,
		Saving,
		Error,
		ConfirmDialog,
	},
	data: () =>
		({
			pack: {},
			packInfoRequiredProps: [
				'name',
				'description',
				'volume',
				'accent',
				'price',
				'path',
				'addedOn',
				'expiredOn',
				'type',
				'genre',
				'artist',
			],
			savingMessage: 'Saving Pack',
			savingColor: 'primary',
			errorMessage: 'Error',
			showError: false,
			entities: null,
			isLoading: true,
			isSaving: false,
			pendingUploads: {},
			variantGroups: {},
			variantPendingUploads: {},
			values,
		} as PUTInterfaces.PUTDataInterface),
	methods: {
		async load() {
			this.isLoading = true;
			try {
				await this.loadEntities();
				if (this.packId && this.packId !== 'new') {
					await this.loadPack(this.packId);
					this.parseVariantGroups();
				}
			} catch (error) {
				// handle data loading error
			}
			this.isLoading = false;
		},
		async loadEntities() {
			const entityTypePromises = entityTypes.map(e =>
				NodeService.getItems(e).then(response => this.entitize(response.data))
			);
			try {
				const responses = await Promise.all(entityTypePromises).then(results =>
					this.entitize(results, true)
				);
				this.entities = responses as any;
			} catch (error) {
				this.handleError('Failed to load entities');
			}
		},
		/*
			::::::::::::::::::::
			Pack
			::::::::::::::::::::
		*/
		async populatePack(
			pack: PUTInterfaces.RawPackInterface
		): Promise<PUTInterfaces.PackInterface> {
			let updates: any = {};
			try {
				// populate type
				const type =
					pack.type && typeof pack.type === 'string'
						? await NodeService.getItem('pack-type', pack.type).then(
								result => result.data
						  )
						: pack.type;

				// populate genre
				const genre =
					pack.genre && typeof pack.genre === 'string'
						? await NodeService.getItem('genre', pack.genre).then(
								result => result.data
						  )
						: pack.genre;

				// populate artist
				const artist =
					pack.artist && typeof pack.artist === 'string'
						? await NodeService.getItem('artist', pack.artist).then(
								result => result.data
						  )
						: pack.artist;

				// populate loops
				const loopPromises = pack.loops.map(loopId =>
					NodeService.getItem('loop', loopId).then(result => {
						const loop = result.data as PUTInterfaces.LoopInterface;
						return this.entitizeLoop(loop);
					})
				);
				const loops = await Promise.all(loopPromises).then(results =>
					this.entitize(results)
				);

				// entitize all the things
				const categories = this.entitize(pack.categories);
				const styles = this.entitize(pack.styles);
				const tags = this.entitize(pack.tags);
				const tempos = this.entitize(pack.tempos);

				updates = {
					type,
					genre,
					artist,
					loops,
					categories,
					styles,
					tags,
					tempos,
				};
			} catch (error) {
				this.handleError('Failed to populate pack!');
			}

			return {
				...pack,
				...updates,
			} as PUTInterfaces.PackInterface;
		},
		async loadPack(packId: string) {
			try {
				// load pack
				const pack = (await NodeService.getItem('pack', packId)).data;
				this.pack = await this.populatePack(pack);
			} catch (error) {
				this.handleError('Failed to load pack');
			}
		},
		async createPack() {
			const flattenedPack = this.flattenPack(this.pack);
			const createdPack = (await NodeService.createItem('pack', flattenedPack))
				.data;
			this.pack = await this.populatePack(createdPack);
		},
		async deletePack() {
			const variantDeletePromises: Promise<any>[] = [];
			const midiDeletePromises: Promise<any>[] = [];
			const loopDeletePromises: Promise<any>[] = [];
			Object.keys(this.pack.loops).forEach(loopId => {
				Object.keys(this.pack.loops[loopId].variants).forEach(variantId => {
					variantDeletePromises.push(
						NodeService.deleteItem('variant', variantId)
					);
				});
				Object.keys(this.pack.loops[loopId].midi).forEach(midiId => {
					midiDeletePromises.push(NodeService.deleteItem('midi', midiId));
				});
				loopDeletePromises.push(NodeService.deleteItem('loop', loopId));
			});

			await Promise.all(variantDeletePromises);
			await Promise.all(loopDeletePromises);
			await NodeService.deleteItem('pack', this.pack._id);
			this.$router.push({ name: 'pack-upload-tool-new' });
		},
		handleDeletePack() {
			const { confirmDialog } = this.$refs as any;
			if (confirmDialog) {
				confirmDialog.showDialog(
					'Action cannot be undone!',
					`Are you sure you want to delete ${this.pack.name}?`,
					null,
					this.deletePack,
					null
				);
			}
		},
		async updatePack() {
			const flattenedPack = this.flattenPack(this.pack);
			await NodeService.updateItem('pack', flattenedPack);
			await this.loadPack(this.pack._id);
		},
		hasRequiredPackInfoProps(updates: PUTInterfaces.UpdatePackInterface[]) {
			return this.packInfoRequiredProps.every(
				p =>
					!!this.pack[p] ||
					updates.some(update => {
						return update.prop === p;
					})
			);
		},
		async handleUpdatePack(updates: PUTInterfaces.UpdatePackInterface[]) {
			try {
				updates.forEach(update => {
					this.pack[update.prop] = update.val;
				});
				this.pack = {
					...this.pack,
				};

				this.isSaving = true;
				this.savingMessage = 'Saving Pack';
				this.savingColor = 'primary';

				if (this.pack._id) {
					await this.updatePack();
				} else {
					if (this.hasRequiredPackInfoProps(updates)) {
						await this.createPack();
					} else {
						this.savingMessage = CANNOT_SAVE_PACK_MESSAGE;
						this.savingColor = 'error';
					}
				}

				if (this.savingMessage === CANNOT_SAVE_PACK_MESSAGE) {
					setTimeout(() => {
						this.isSaving = false;
					}, 750);
				} else {
					this.isSaving = false;
				}
			} catch (error) {
				this.handleError('Failed to update pack!');
			}
		},
		flattenPack(
			pack: PUTInterfaces.PackInterface
		): PUTInterfaces.RawPackInterface {
			const flattenEntityCollectionKeys = [
				'loops',
				'categories',
				'styles',
				'tags',
				'tempos',
			];
			const flattenEntityKeys = ['artist', 'genre', 'type'];
			const newPack: any = {};
			Object.keys(pack).forEach(key => {
				if (flattenEntityCollectionKeys.includes(key)) {
					newPack[key] = this.flattenEntityCollection(pack[key]);
				} else if (flattenEntityKeys.includes(key)) {
					newPack[key] = this.flattenEntity(pack[key]);
				} else {
					newPack[key] = pack[key];
				}
			});
			return newPack;
		},
		/*
			::::::::::::::::::::
			Uploads
			::::::::::::::::::::
		*/
		addPendingUpload(upload: PUTInterfaces.PendingUploadInterface) {
			this.pendingUploads = {
				...this.pendingUploads,
				[upload.prop]: upload,
			};
		},
		removeUpload(prop: string) {
			delete this.pendingUploads[prop];
		},
		handlePackAddUpload(upload: PUTInterfaces.PendingUploadInterface) {
			if (this.pendingUploads[upload.prop]) {
				this.removeUpload(upload.prop);
			}
			this.addPendingUpload(upload);
		},
		handleClearPendingUploads() {
			this.pendingUploads = {};
		},
		async handleCompletePendingUploads() {
			try {
				if (Object.keys(this.pendingUploads).length) {
					const uploadPromises: Promise<any>[] = [];
					for (const key in this.pendingUploads) {
						const pendingUpload = this.pendingUploads[key];
						const type = pendingUpload.prop.replace('Url', '');
						const fileName = `${type}-${Date.now()}.${
							pendingUpload.file.type.split('/')[1]
						}`;

						const formData = new FormData();
						formData.append('file', pendingUpload.file);

						uploadPromises.push(
							NodeService.uploadItem(
								'pack',
								this.pack._id,
								type,
								fileName,
								formData
							).then(result => ({ [key]: result.data }))
						);
					}
					const uploadResults = await Promise.all(uploadPromises);
					const uploadResultsCombined = { ...uploadResults };

					const updatePackPromises: any[] = [];
					for (const key in uploadResultsCombined) {
						updatePackPromises.push(
							this.handleUpdatePack([
								{
									prop: key,
									val: uploadResultsCombined[key][key],
								},
							])
						);
					}
					await Promise.all(updatePackPromises);
					this.pendingUploads = {};
				}
			} catch (error) {
				this.handleError('Failed to upload pending uploads!');
			}
		},
		async handleDeleteUpload(obj: PUTInterfaces.UpdatePackInterface) {
			// TODO: Find a way to delete pack uploads
			// await this.handleUpdatePack([obj]);
		},
		/*
			::::::::::::::::::::
			Loops
			::::::::::::::::::::
		*/
		async handleGenerateLoops(num: number) {
			const loopPromises: Promise<any>[] = [];
			for (let i = 1; i <= num; i += 1) {
				const name = `Track ${i}`;
				const loop = {
					name,
					fileName: `${snakeCase(name)}.mp3`,
					trackNumber: i,
				};
				loopPromises.push(
					NodeService.createItem('loop', loop).then(result => result.data)
				);
			}
			const generatedLoops = await Promise.all(loopPromises);
			const loops = generatedLoops.map(loop => this.entitizeLoop(loop));
			await this.handleUpdatePack([
				{
					prop: 'loops',
					val: this.entitize(loops) as any,
				},
			]);
		},
		async handleDeleteLoops() {
			try {
				const deleteLoopsPromises: Promise<any>[] = [];
				const deleteMidisPromises: Promise<any>[] = [];
				const deleteVariantsPromises: Promise<any>[] = [];
				Object.keys(this.pack.loops).forEach(loopId => {
					Object.keys(this.pack.loops[loopId].variants).forEach(variantId => {
						deleteVariantsPromises.push(
							NodeService.deleteItem('variant', variantId)
						);
					});
					Object.keys(this.pack.loops[loopId].midi).forEach(midiId => {
						deleteMidisPromises.push(NodeService.deleteItem('midi', midiId));
					});
					deleteLoopsPromises.push(NodeService.deleteItem('loop', loopId));
				});

				await Promise.all(deleteVariantsPromises);
				await Promise.all(deleteMidisPromises);
				await Promise.all(deleteLoopsPromises);

				// Recalculate pack styles on loops
				const packUpdates: PUTInterfaces.UpdatePackInterface[] = [];

				packUpdates.push({
					prop: 'loops',
					val: {},
				});

				packUpdates.push({
					prop: 'styles',
					val: {},
				});

				packUpdates.push({
					prop: 'tempos',
					val: {},
				});

				packUpdates.push({
					prop: 'categories',
					val: {},
				});

				await this.handleUpdatePack(packUpdates);
				this.parseVariantGroups();
			} catch (error) {
				this.handleError('Failed to delete loops!');
			}
		},
		async handleDeleteLoop(loopId: string) {
			try {
				const deleteLoopMidisPromises: Promise<any>[] = [];
				const deleteLoopVariantsPromises: Promise<any>[] = [];
				Object.keys(this.pack.loops[loopId].variants).forEach(variantId => {
					deleteLoopVariantsPromises.push(
						NodeService.deleteItem('variant', variantId)
					);
				});
				Object.keys(this.pack.loops[loopId].midi).forEach(midiId => {
					deleteLoopMidisPromises.push(NodeService.deleteItem('midi', midiId));
				});

				let remainingPackLoops = {};
				let remainingPackTempos = {};
				let remainingPackStyles = {};
				let remainingPackCategories = {};

				Object.keys(this.pack.loops).forEach(packLoopId => {
					if (packLoopId !== loopId) {
						remainingPackLoops = {
							...remainingPackLoops,
							[packLoopId]: this.pack.loops[packLoopId],
						};
						remainingPackTempos = {
							...remainingPackTempos,
							...this.pack.loops[packLoopId].tempos,
						};
						remainingPackStyles = {
							...remainingPackStyles,
							...this.pack.loops[packLoopId].styles,
						};
						if (this.pack.loops[packLoopId].category) {
							remainingPackCategories = {
								...remainingPackCategories,
								[this.pack.loops[packLoopId].category._id]: this.pack.loops[
									packLoopId
								].category,
							};
						}
					}
				});

				await Promise.all(deleteLoopMidisPromises);
				await Promise.all(deleteLoopVariantsPromises);
				await NodeService.deleteItem('loop', loopId);

				// Recalculate pack styles on loops
				const packUpdates: PUTInterfaces.UpdatePackInterface[] = [];

				packUpdates.push({
					prop: 'loops',
					val: remainingPackLoops,
				});

				packUpdates.push({
					prop: 'styles',
					val: remainingPackStyles,
				});

				packUpdates.push({
					prop: 'tempos',
					val: remainingPackTempos,
				});

				packUpdates.push({
					prop: 'categories',
					val: remainingPackCategories,
				});

				await this.handleUpdatePack(packUpdates);
				this.parseVariantGroups();
			} catch (error) {
				this.handleError('Failed to delete loop!');
			}
		},
		async handleUpdateLoop(updates: PUTInterfaces.UpdateLoopInterface[]) {
			try {
				const loop: PUTInterfaces.LoopInterface = {
					...this.pack.loops[updates[0].loopId],
				};

				let midiUpdatePromise = null;
				for (const update of updates) {
					loop[update.prop] = update.val;
					if (update.prop === 'name') {
						loop.fileName = `${snakeCase(loop.name)}.mp3`;

						if (Object.keys(loop.midi).length) {
							const midiId = Object.keys(loop.midi)[0];
							const midi = loop.midi[midiId] as PUTInterfaces.MidiInterface;
							midi.name = loop.name;
							midi.fileName = `${loop.fileName.split('.mp3')[0]}.midi`;
							midiUpdatePromise = NodeService.updateItem('midi', midi) as any;
						}
					}
				}

				if (midiUpdatePromise) {
					await midiUpdatePromise;
				}

				const flattenedLoop = this.flattenLoop(loop);

				const updatedLoop = (
					await NodeService.updateItem('loop', flattenedLoop)
				).data;
				const entitizedLoop = this.entitizeLoop(updatedLoop);

				const packUpdates: PUTInterfaces.UpdatePackInterface[] = [
					{
						prop: 'loops',
						val: {
							...this.pack.loops,
							[entitizedLoop._id]: entitizedLoop,
						},
					},
				];

				// Recalculate pack categories
				const categoryUpdate = updates.find(
					update => update.prop === 'category'
				);
				if (categoryUpdate) {
					const categories = {};
					Object.keys(this.pack.loops).forEach(loopId => {
						const loopCategory = this.pack.loops[loopId].category;
						if (loopId === entitizedLoop._id) {
							if (!categories[categoryUpdate.val._id]) {
								categories[categoryUpdate.val._id] = categoryUpdate.val;
							}
						} else if (
							loopCategory &&
							loopCategory._id &&
							!categories[loopCategory._id]
						) {
							categories[loopCategory._id] = loopCategory;
						}
					});
					packUpdates.push({
						prop: 'categories',
						val: categories,
					});
				}

				await this.handleUpdatePack(packUpdates);
			} catch (error) {
				this.handleError('Failed to update loop!');
			}
		},
		entitizeLoop(loop) {
			const midi = this.entitize(loop.midi);
			const productions = this.entitize(loop.productions);
			const styles = this.entitize(loop.styles);
			const tempos = this.entitize(loop.tempos);
			const variants = this.entitize(loop.variants);

			return {
				...loop,
				midi,
				productions,
				styles,
				tempos,
				variants,
			};
		},
		flattenLoop(loop) {
			const flattenEntityCollectionKeys = [
				'midi',
				'productions',
				'styles',
				'variants',
				'tempos',
			];
			const flattenEntityKeys = ['category'];
			const newLoop = {};
			Object.keys(loop).forEach(key => {
				if (flattenEntityCollectionKeys.includes(key)) {
					newLoop[key] = this.flattenEntityCollection(loop[key]);
				} else if (flattenEntityKeys.includes(key)) {
					newLoop[key] = this.flattenEntity(loop[key]);
				} else {
					newLoop[key] = loop[key];
				}

				if (Array.isArray(newLoop[key]) && !newLoop[key].length) {
					delete newLoop[key];
				} else if (
					typeof newLoop[key] === 'object' &&
					!Object.keys(newLoop[key]).length
				) {
					delete newLoop[key];
				}
			});
			return newLoop;
		},
		async handleLoopMidiUpload({
			upload,
			callback,
		}: {
			upload: PUTInterfaces.LoopMidiUploadInterface;
			callback: Function;
		}) {
			try {
				const createdMidi = (
					await NodeService.createItem('midi', {
						name: upload.midiName,
						fileName: upload.midiFileName,
					})
				).data;

				const formData = new FormData();
				formData.append('file', upload.file);

				const updatedMidi = (
					await NodeService.uploadItem(
						'midi',
						createdMidi._id,
						'midi',
						upload.midiFileName,
						formData
					)
				).data;

				const loopUpdates: PUTInterfaces.UpdateLoopInterface[] = [];

				loopUpdates.push({
					loopId: upload.loop._id,
					prop: 'midi',
					val: this.entitize([updatedMidi]),
				});

				// Manually update loops to cut down on requests
				const loopToUpdateCopy = { ...upload.loop };
				loopUpdates.forEach(update => {
					loopToUpdateCopy[update.prop] = update.val;
				});

				const flattenedLoop = this.flattenLoop(loopToUpdateCopy);
				const updatedLoop: PUTInterfaces.LoopInterface = (
					await NodeService.updateItem('loop', flattenedLoop)
				).data;
				updatedLoop.midi = [updatedMidi];
				const entitizedLoop = this.entitizeLoop(updatedLoop);
				this.pack.loops[updatedLoop._id] = entitizedLoop;
			} catch (error) {
				this.handleError('Failed to upload loop midi!');
			}
			callback();
		},
		/*
			::::::::::::::::::::
			Variant Groups
			::::::::::::::::::::
		*/
		parseVariantGroups() {
			this.variantGroups = {};
			Object.keys(this.pack.loops).forEach(loopId => {
				const loop: PUTInterfaces.LoopInterface = this.pack.loops[loopId];

				Object.keys(loop.variants).forEach(variantId => {
					const variant: PUTInterfaces.VariantInterface =
						loop.variants[variantId];

					const matchingVariantGroupId = Object.keys(this.variantGroups).find(
						variantGroupId => {
							const variantGroup = this.variantGroups[variantGroupId];
							if (
								variantGroup &&
								variantGroup.style &&
								variantGroup.tempo &&
								variantGroup.production
							) {
								return (
									variantGroup.style._id === variant.style._id &&
									variantGroup.tempo._id === variant.tempo._id &&
									variantGroup.production._id === variant.production._id
								);
							}
							return false;
						}
					);

					if (matchingVariantGroupId) {
						this.variantGroups[matchingVariantGroupId].variants = {
							...this.variantGroups[matchingVariantGroupId].variants,
							[variant._id]: variant,
						};
					} else {
						const newVariantGroup: PUTInterfaces.VariantGroupInterface = {
							id: this.generateGUID(),
							created: true,
							expanded: false,
							variantFiles: null,
							variantNameTemplate: DEFAULT_VARIANT_NAME_TEMPLATE,
							variantFileNameTemplate: DEFAULT_VARIANT_FILENAME_TEMPLATE,
							style: variant.style,
							tempo: variant.tempo,
							production: variant.production,
							variants: { [variant._id]: variant },
						};

						this.variantGroups = {
							...this.variantGroups,
							[newVariantGroup.id]: newVariantGroup,
						};
					}
				});
			});
		},
		handleCreateVariantGroup() {
			const newVariantGroup: PUTInterfaces.VariantGroupInterface = {
				id: this.generateGUID(),
				expanded: false,
				created: false,
				variantFiles: null,
				variantNameTemplate: DEFAULT_VARIANT_NAME_TEMPLATE,
				variantFileNameTemplate: DEFAULT_VARIANT_FILENAME_TEMPLATE,
				style: null,
				tempo: null,
				production: null,
				variants: {},
			};

			this.variantGroups = {
				...this.variantGroups,
				[newVariantGroup.id]: newVariantGroup,
			};
		},
		handleProcessLocalVariantFiles(
			e: Event,
			variantGroup: PUTInterfaces.VariantGroupInterface
		) {
			const fileReader = new FileReader();
			const { files } = e.target as HTMLInputElement;

			if (files) {
				const variantsUpload: PUTInterfaces.PendingVariantUploadInterface = {
					variantGroupId: variantGroup.id,
					files,
				};

				this.addVariantsUpload(variantsUpload);
			}
		},
		addVariantsUpload(upload: PUTInterfaces.PendingVariantUploadInterface) {
			if (this.variantPendingUploads[upload.variantGroupId]) {
				this.removeVariantsUpload(upload.variantGroupId);
			}
			this.variantPendingUploads = {
				...this.variantPendingUploads,
				[upload.variantGroupId]: upload,
			};
		},
		removeVariantsUpload(variantGroupId: string) {
			delete this.variantPendingUploads[variantGroupId];
		},
		async handleDeleteVariantGroup(variantGroupId: string) {
			const variantGroup = this.variantGroups[variantGroupId];

			const variantDeletePromises: Promise<any>[] = [];
			const loopUpdatePromises: Promise<any>[] = [];
			let remainingPackStyles = {};
			let remainingPackTempos = {};
			Object.keys(variantGroup.variants).forEach(variantId => {
				variantDeletePromises.push(
					NodeService.deleteItem('variant', variantId)
				);

				const loopToUpdateId = Object.keys(this.pack.loops).find(
					loopId => this.pack.loops[loopId].variants[variantId]
				);
				const loopToUpdate: PUTInterfaces.LoopInterface = this.pack.loops[
					loopToUpdateId as string
				];
				const loopUpdates: PUTInterfaces.UpdateLoopInterface[] = [];
				let remainingVariants = {};
				let remainingTempos = {};
				let remainingStyles = {};
				let remainingProductions = {};
				Object.keys(loopToUpdate.variants).forEach(loopVariantId => {
					if (loopVariantId !== variantId) {
						const loopVariant: PUTInterfaces.VariantInterface =
							loopToUpdate.variants[loopVariantId];
						remainingVariants = {
							...remainingVariants,
							[loopVariantId]: loopVariant,
						};
						remainingTempos = {
							...remainingTempos,
							[loopVariant.tempo._id]: loopVariant.tempo,
						};
						remainingStyles = {
							...remainingStyles,
							[loopVariant.style._id]: loopVariant.style,
						};
						remainingProductions = {
							...remainingProductions,
							[loopVariant.production._id]: loopVariant.production,
						};

						// Keep track of remaining items for Pack
						remainingPackTempos = {
							...remainingPackTempos,
							[loopVariant.tempo._id]: loopVariant.tempo,
						};
						remainingPackStyles = {
							...remainingPackStyles,
							[loopVariant.style._id]: loopVariant.style,
						};
					}
				});

				loopUpdates.push({
					loopId: loopToUpdate._id,
					prop: 'tempos',
					val: remainingTempos,
				});
				loopUpdates.push({
					loopId: loopToUpdate._id,
					prop: 'styles',
					val: remainingStyles,
				});
				loopUpdates.push({
					loopId: loopToUpdate._id,
					prop: 'productions',
					val: remainingProductions,
				});

				// Manually update loops to cut down on requests
				const loopToUpdateCopy = { ...loopToUpdate };
				loopUpdates.forEach(update => {
					loopToUpdateCopy[update.prop] = update.val;
				});

				const flattenedLoop = this.flattenLoop(loopToUpdateCopy);
				loopUpdatePromises.push(
					NodeService.updateItem('loop', flattenedLoop).then(
						result => result.data
					)
				);
			});

			await Promise.all(variantDeletePromises);
			await Promise.all(loopUpdatePromises);

			// Recalculate pack styles on variants
			const packUpdates: PUTInterfaces.UpdatePackInterface[] = [];

			packUpdates.push({
				prop: 'styles',
				val: remainingPackStyles,
			});

			packUpdates.push({
				prop: 'tempos',
				val: remainingPackTempos,
			});

			await this.handleUpdatePack(packUpdates);

			const {
				[variantGroupId]: removedPendingUpload,
				...remainingVariantPendingUploads
			} = this.variantPendingUploads;
			this.variantPendingUploads = { ...remainingVariantPendingUploads };

			const {
				[variantGroupId]: removedVariantGroup,
				...remainingVariantGroups
			} = this.variantGroups;
			this.variantGroups = { ...remainingVariantGroups };
		},
		async handleCreateVariants(variantGroupId: string) {
			const variantGroup = this.variantGroups[variantGroupId];
			const variantGroupUpload = this.variantPendingUploads[variantGroupId];

			if (
				variantGroupUpload &&
				variantGroupUpload.files &&
				variantGroupUpload.files.length
			) {
				let tempVariantInfo = {};

				const variantPromises: Promise<any>[] = [];
				for (const file of variantGroupUpload.files as any) {
					const regex = /\d+/;
					const trackNumber = file.name.match(regex)[0];

					const loopToUpdateId = Object.keys(this.pack.loops).find(loopId => {
						return (
							parseInt(this.pack.loops[loopId].trackNumber, 10) ===
							parseInt(trackNumber, 10)
						);
					});
					const loopToUpdate = this.pack.loops[loopToUpdateId as string];

					if (
						loopToUpdate &&
						variantGroup.tempo &&
						variantGroup.style &&
						variantGroup.production
					) {
						const name = this.generateVariantName(
							variantGroup.variantNameTemplate,
							loopToUpdate.name,
							this.pack.name,
							variantGroup.tempo.name,
							variantGroup.style.name,
							loopToUpdate.category.name,
							variantGroup.production.name
						);
						const fileName = this.generateVariantFileName(
							variantGroup.variantFileNameTemplate,
							loopToUpdate.name,
							this.pack.name,
							variantGroup.tempo.name,
							variantGroup.style.name,
							loopToUpdate.category.name,
							variantGroup.production.name
						);
						const formData = new FormData();
						formData.append('file', file);

						const newVariant = {
							name,
							fileName,
							tempo: variantGroup.tempo._id,
							style: variantGroup.style._id,
							category: loopToUpdate.category._id,
							production: variantGroup.production._id,
						};

						tempVariantInfo = {
							...tempVariantInfo,
							[newVariant.name]: {
								fileName,
								formData,
								loopToUpdateId: loopToUpdate._id,
							},
						};

						variantPromises.push(
							NodeService.createItem('variant', newVariant).then(
								result => result.data
							)
						);
					}
				}

				const variants: PUTInterfaces.VariantInterface[] = await Promise.all(
					variantPromises
				);

				const variantFilePromises: Promise<any>[] = [];
				for (const variant of variants) {
					const tempInfo = tempVariantInfo[variant.name];

					if (tempInfo) {
						variantFilePromises.push(
							NodeService.uploadItem(
								'variant',
								variant._id,
								'loop',
								tempInfo.fileName,
								tempInfo.formData
							).then(result => result.data)
						);
					}
				}

				const updatedVariants = await Promise.all(variantFilePromises);

				// Repopulate variants manually to reduce network requests
				for (const variant of updatedVariants) {
					if (
						this.entities &&
						this.entities.category &&
						this.entities.production &&
						this.entities.style &&
						this.entities.tempo
					) {
						variant.category = this.entities.category[variant.category];
						variant.production = this.entities.production[variant.production];
						variant.style = this.entities.style[variant.style];
						variant.tempo = this.entities.tempo[variant.tempo];
					}
				}

				let newTempos = {};
				let newStyles = {};

				const loopUpdatePromises: Promise<any>[] = [];
				for (const variant of updatedVariants as PUTInterfaces.VariantInterface[]) {
					const tempInfo = tempVariantInfo[variant.name];

					if (tempInfo) {
						variantGroup.variants = {
							...variantGroup.variants,
							[variant._id]: variant,
						};

						const loopToUpdate = this.pack.loops[tempInfo.loopToUpdateId];

						const loopUpdates: PUTInterfaces.UpdateLoopInterface[] = [];

						loopUpdates.push({
							loopId: loopToUpdate._id,
							prop: 'variants',
							val: {
								...loopToUpdate.variants,
								[variant._id]: variant,
							},
						});

						// Update loops
						if (!loopToUpdate.tempos[variant.tempo._id]) {
							loopUpdates.push({
								loopId: loopToUpdate._id,
								prop: 'tempos',
								val: {
									...loopToUpdate.tempos,
									[variant.tempo._id]: variant.tempo,
								},
							});
						}

						if (!loopToUpdate.styles[variant.style._id]) {
							loopUpdates.push({
								loopId: loopToUpdate._id,
								prop: 'styles',
								val: {
									...loopToUpdate.styles,
									[variant.style._id]: variant.style,
								},
							});
						}

						if (!loopToUpdate.productions[variant.production._id]) {
							loopUpdates.push({
								loopId: loopToUpdate._id,
								prop: 'productions',
								val: {
									...loopToUpdate.productions,
									[variant.production._id]: variant.production,
								},
							});
						}

						// Update entites for pack
						if (!newTempos[variant.tempo._id]) {
							newTempos = {
								...newTempos,
								[variant.tempo._id]: variant.tempo,
							};
						}
						if (!newStyles[variant.style._id]) {
							newStyles = {
								...newStyles,
								[variant.style._id]: variant.style,
							};
						}

						// Manually update loops to cut down on requests
						const loopToUpdateCopy = { ...loopToUpdate };
						loopUpdates.forEach(update => {
							loopToUpdateCopy[update.prop] = update.val;
						});

						const flattenedLoop = this.flattenLoop(loopToUpdateCopy);
						loopUpdatePromises.push(
							NodeService.updateItem('loop', flattenedLoop).then(
								result => result.data
							)
						);
					}
				}

				await Promise.all(loopUpdatePromises);

				// Recalculate pack styles on variants
				const packUpdates: PUTInterfaces.UpdatePackInterface[] = [];

				let styles = {};
				Object.keys(this.pack.loops).forEach(loopId => {
					const loop: PUTInterfaces.LoopInterface = this.pack.loops[loopId];
					Object.keys(loop.styles).forEach(styleId => {
						if (!styles[styleId]) {
							styles = {
								...styles,
								[styleId]: loop.styles[styleId],
							};
						}
					});
				});
				Object.keys(newStyles).forEach(styleId => {
					if (!styles[styleId]) {
						styles = {
							...styles,
							[styleId]: newStyles[styleId],
						};
					}
				});
				packUpdates.push({
					prop: 'styles',
					val: styles,
				});

				let tempos = {};
				Object.keys(this.pack.loops).forEach(loopId => {
					const loop: PUTInterfaces.LoopInterface = this.pack.loops[loopId];
					Object.keys(loop.tempos).forEach(tempoId => {
						if (!tempos[tempoId]) {
							tempos = {
								...tempos,
								[tempoId]: loop.tempos[tempoId],
							};
						}
					});
				});
				Object.keys(newTempos).forEach(tempoId => {
					if (!tempos[tempoId]) {
						tempos = {
							...tempos,
							[tempoId]: newTempos[tempoId],
						};
					}
				});
				packUpdates.push({
					prop: 'tempos',
					val: tempos,
				});

				await this.handleUpdatePack(packUpdates);

				this.variantGroups = {
					...this.variantGroups,
					[variantGroup.id]: {
						...variantGroup,
						created: true,
					},
				};

				const {
					[variantGroup.id]: completedVariantGroupUpload,
					remainingVariantGroupUploads,
				} = this.variantPendingUploads;

				this.variantPendingUploads = {
					...(remainingVariantGroupUploads as any),
				};
			}
		},
		generateVariantFileName(
			template,
			loopName,
			packName,
			tempoName,
			styleName,
			categoryName,
			productionName
		) {
			const matches = packName.match(/\b(\w)/g);
			const packAbbv = matches.map(m => m.toUpperCase()).join('');

			return template
				.replace('{loopName}', snakeCase(loopName))
				.replace('{packAbbv}', packAbbv)
				.replace('{tempo}', snakeCase(tempoName))
				.replace('{style}', snakeCase(styleName))
				.replace('{category}', snakeCase(categoryName))
				.replace('{production}', snakeCase(productionName));
		},
		generateVariantName(
			template,
			loopName,
			packName,
			tempoName,
			styleName,
			categoryName,
			productionName
		) {
			const matches = packName.match(/\b(\w)/g);
			const packAbbv = matches.map(m => m.toUpperCase()).join('');

			return template
				.replace('{loopName}', snakeCase(loopName))
				.replace('{packAbbv}', packAbbv)
				.replace('{tempo}', snakeCase(tempoName))
				.replace('{style}', snakeCase(styleName))
				.replace('{category}', snakeCase(categoryName))
				.replace('{production}', snakeCase(productionName));
		},
		/*
			::::::::::::::::::::
			Utility
			::::::::::::::::::::
		*/
		entitize(
			items: any,
			useTypes: boolean = false
		): { [id: string]: PUTInterfaces.PUTEntityInterfaces } {
			return items.reduce(
				(entities, item, index) => ({
					...entities,
					[useTypes ? entityTypes[index] : item._id]: item,
				}),
				{}
			);
		},
		flattenEntityCollection(
			obj: PUTInterfaces.entities<PUTInterfaces.PUTEntityInterfaces>
		) {
			return Object.keys(obj).reduce(
				(items, key) => [...items, key] as any,
				[]
			);
		},
		flattenEntity(obj: PUTInterfaces.PUTEntityInterfaces) {
			return obj._id;
		},
		handleError(message) {
			this.errorMessage = message;
			this.showError = true;

			setTimeout(() => {
				this.errorMessage = 'Error';
				this.showError = false;
			}, 2000);
		},
		generateGUID() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
				// eslint-disable-next-line
				const r = (Math.random() * 16) | 0,
					v = c === 'x' ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			});
		},
	},
	watch: {
		packId: {
			immediate: true,
			handler(curr, prev) {
				this.pack = {} as any;
				this.pendingUploads = {};
				this.variantGroups = {};
				this.load();
			},
		},
	},
});
